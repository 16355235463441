//* Component imports
import ChartComponent from '../components/Chart';
import GeneratedEnergyTable from '../components/GeneratedEnergyTable';

//* Material-UI imports
import { FormControl, Grid, InputLabel, MenuItem, Select, Paper } from '@mui/material';

const GeneratedEnergyPage = ({ generatedEnergy, date, panelInfoType, handlePanelTypeChange }) => {
    return (
        <>
            {/* Panel Info Type Dropdown */}
            {(generatedEnergy?.values && generatedEnergy?.values?.length > 0) ? <Grid item xs={12} sm={12} md={12} lg={12}>
                <FormControl fullWidth>
                    <InputLabel id="result-type">Generated Energy</InputLabel>
                    <Select
                        id="result-type"
                        label='>Generated Energy'
                        value={panelInfoType}
                        labelId="result-type-label"
                        onChange={handlePanelTypeChange}>
                        <MenuItem value="table">Table</MenuItem>
                        <MenuItem value="chart">Chart</MenuItem>
                    </Select>
                </FormControl>
            </Grid> : null}

            {(generatedEnergy?.values?.length > 0 && panelInfoType) ? <Grid item xs={12} sm={12} md={12} lg={12}>
                {/* Generated Energy Table */}
                {panelInfoType === 'table' ? <GeneratedEnergyTable component={Paper} rows={generatedEnergy?.values} /> : null}

                {/* Generated Energy Chart */}
                {panelInfoType === 'chart' ? <ChartComponent params={generatedEnergy.values} unit={'W/h'} /> : null}
            </Grid> : null}

        </>
    )
}

export default GeneratedEnergyPage;