import moment from 'moment';
import SunCalc from 'suncalc';

//* Function to format a date object
function formatDate(date) {
    const monthName = date.toLocaleString('default', { month: 'long' });
    const seconds = date.getSeconds();
    const hours = date.getHours();
    const minutes = date.getMinutes();
    const formattedMinutes = minutes < 10 ? '0' + minutes : minutes;
    const formattedSeconds = seconds < 10 ? '0' + seconds : seconds;

    return `${monthName} ${date.getDay()}, ${date.getFullYear()} ${hours}:${formattedMinutes}:${formattedSeconds}`;
}

//* Function to calculate the sun position and energy generation for a specific date and time
function getSunPosition(date, lat, lon, panelAzimuth, panelAltitude, panelPower) {
    const dateObj = moment(date).toDate();
    const sunPosition = SunCalc.getPosition(dateObj, lat, lon);

    //* Get azimuth and altitude from sun calc library
    const sunAzimuth = (sunPosition?.azimuth * 180 / Math.PI + 180) % 360;
    const sunAltitude = sunPosition?.altitude * 180 / Math.PI;

    //* Parse panel azimuth and altitude of degrees to radians
    const panelAzimuthAtRadians = panelAzimuth * (Math.PI / 180);
    const panelAltitudeAtRadians = panelAltitude * (Math.PI / 180);

    //* Calculate angle of sun to panel by azimuth and altitude
    const horizontalSunPanelAngle = Math.abs(sunAzimuth * (Math.PI / 180) - panelAzimuthAtRadians);
    const verticalSunPanelAngle = Math.abs(sunAltitude * (Math.PI / 180) - panelAltitudeAtRadians);

    let coefficient = 0;

    //* Check if the sun is in the acceptable azimuth and altitude range
    if (Math.cos(horizontalSunPanelAngle) > 0 && Math.cos(verticalSunPanelAngle) > 0) {
        coefficient = Math.abs(Math.cos(horizontalSunPanelAngle) * Math.cos(verticalSunPanelAngle)).toFixed(2);
    }

    const generatedEnergy = (Number(panelPower) * coefficient).toFixed(2);

    return { horizontalSunPanelAngle: (horizontalSunPanelAngle * (180 / Math.PI)).toFixed(2), verticalSunPanelAngle: (verticalSunPanelAngle * (180 / Math.PI)).toFixed(2), coefficient, generatedEnergy };
}

export function calculateDailyEnergyHandler(date, lat, lon, panelAzimuth, panelAltitude, panelPower) {
    const times = SunCalc.getTimes(date, lat, lon);
    const roundedHours = getRoundHours(times.sunrise, times.sunset);

    return roundedHours.map((hour) => {
        const dateObj = moment(date).startOf('day').add(Math.floor(hour), 'hours').add(Math.round((hour - Math.floor(hour)) * 60), 'minutes');
        const formattedDate = formatDate(dateObj.toDate());
        const sunPosition = getSunPosition(dateObj, lat, lon, panelAzimuth, panelAltitude, panelPower);

        return { time: dateObj.format('YYYY-MM-DD HH:mm'), formattedDate, ...sunPosition };
    });
}

export function calculateYearlyEnergy(date, lat, lon, panelAzimuth, panelAltitude, panelPower) {
    let totalEnergy = 0;

    for (let month = 0; month < 12; month++) {
        const firstDayOfMonth = moment(date).startOf('year').month(month).startOf('month').toDate();
        const daysInMonth = moment(firstDayOfMonth).daysInMonth();

        for (let day = 1; day <= daysInMonth; day++) {
            const currentDate = moment(firstDayOfMonth).date(day).toDate();
            const sunData = calculateDailyEnergyHandler(currentDate, lat, lon, panelAzimuth, panelAltitude, panelPower);

            sunData.forEach((hourData) => totalEnergy += Number(hourData.generatedEnergy));
        }
    }
    return totalEnergy.toFixed(2);
}

//* Function to get the rounded hours between 10 minutes between sunrise and sunset
function getRoundHours(sunrise, sunset) {
    const startHour = sunrise.getHours();
    const endHour = sunset.getHours() + (sunset.getMinutes() / 60);

    const result = [];
    let currentHour = startHour;

    while (currentHour <= endHour) {
        result.push(currentHour); //* Add the current hour to the result array
        currentHour += 0.16666666667; //* Increment the current hour by 10 minutes in terms of decimal hours
    }
    return result;
}

//* Calculates the generated energy and total generated energy for the given data
export function calculateGeneratedEnergyHandler(data) {
    const result = []; //* Stores the generated energy result
    let totalGeneratedEnergy = 0; //* Stores the total generated energy

    for (let i = 0; i < data.length - 1; i++) {
        const currentValue = data[i];
        const nextValue = data[i + 1];

        //* Calculate the average generated energy between two data points
        const average = (Number(currentValue?.generatedEnergy) + Number(nextValue?.generatedEnergy)) / 2;

        const generatedEnergy = average * (1 / 6); //* Calculate the generated energy for the current time interval (1/6th of an hour)
        totalGeneratedEnergy += generatedEnergy; //* Update the total generated energy

        result.push({ generatedEnergy: Number(generatedEnergy.toFixed(2)), time: `${currentValue.time} - ${nextValue.time}` });
    }
    return { generatedEnergyResult: result, totalGeneratedEnergy: totalGeneratedEnergy.toFixed(2) };
}

// Function to perform linear interpolation
function linearInterpolation(x, x0, y0, x1, y1) {
    return y0 + ((y1 - y0) * (x - x0)) / (x1 - x0);
}

// Function to perform linear interpolation for the given data arrays
export function performLinearInterpolation(data1, data2) {
    // Sort data arrays based on datetime values
    data1.sort((a, b) => new Date(a.datetime) - new Date(b.datetime));
    data2.sort((a, b) => new Date(a.datetime) - new Date(b.datetime));

    // Array to store interpolated data
    const interpolatedData = [];
    // Perform linear interpolation
    for (let i = 0; i < data1.length; i++) {
        const currentData = data1[i];

        // Find the two nearest datetime values in data2
        let prevIndex = -1;
        let nextIndex = -1;
        for (let j = 0; j < data2.length; j++) {
            if (new Date(data2[j].x) <= new Date(currentData.x)) {
                prevIndex = j;
            } else {
                nextIndex = j;
                if (j === data2.length - 1) {
                    nextIndex = j;  // Reached the end of data2, assign the last index to nextIndex
                }
            }
        }

        // Perform linear interpolation if both previous and next points are found
        if (prevIndex !== -1 && nextIndex !== -1) {
            const prevData = data2[prevIndex];
            const nextData = data2[nextIndex];

            // Perform linear interpolation to calculate the interpolated energy value
            const interpolatedEnergy = linearInterpolation(
                new Date(currentData.x),
                new Date(prevData.x),
                Number(prevData.y),
                new Date(nextData.x),
                Number(nextData.y)
            );

            // Add the interpolated data point to the interpolatedData array
            interpolatedData.push({
                x: currentData.x,
                y: interpolatedEnergy.toFixed(2),
            });
        }
    }

    return interpolatedData;
}