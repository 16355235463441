//* React and Date imports
import dayjs from 'dayjs';
import * as yup from 'yup';
import { useState } from 'react';
import { useFormik } from 'formik';

//* Material-UI imports
import { Box, Button, Grid, Paper, Alert, Radio, RadioGroup, FormControlLabel, Typography, TextareaAutosize } from '@mui/material';

//* Component imports
import PanelForm from '../components/forms/PanelForm';
import GeneratedPowerPage from './GeneratedPowerPage';
import GeneratedEnergyPage from './GeneratedEnergyPage';
import CustomDatePicker from '../components/DatePicker';
import LocationForm from '../components/forms/LocationForm';

//* Utility function imports
import { calculateDailyEnergyHandler, calculateGeneratedEnergyHandler, } from '../utils/sunCalcHandler';

//* Validation schema for the form
const eventInfoSchema = yup.object({
    lat: yup.number().required('The field is required'),
    lon: yup.number().required('The field is required'),
    panelAzimuth: yup.number().required('The field is required'),
    panelAltitude: yup.number().required('The field is required'),
    panelPower: yup.number().required('The field is required').min(1, 'Min value of panel power is 1!'),
});

const Dashboard = () => {
    //* State variables
    const [text, setText] = useState('');
    const [data, setData] = useState([]);
    const [error, setError] = useState('');
    const [userData, setUserData] = useState([]);
    const [date, setDate] = useState(new Date());
    const [sunInfoType, setSunInfoType] = useState('table');
    const [panelInfoType, setPanelInfoType] = useState('table');
    const [selectedOption, setSelectedOption] = useState('calculation');
    const [generatedEnergy, setGeneratedEnergy] = useState({ values: [], total: 0 });

    //* Form submission handler
    const onSubmitHandler = async ({ lat, lon, panelAzimuth, panelAltitude, panelPower }) => {
        try {
            setError('');
            const result = calculateDailyEnergyHandler(date?.$d, lat, lon, panelAzimuth, panelAltitude, panelPower);
            result ? setData(result.filter(x => x.generatedEnergy > 0)) : setData([]);

            const { generatedEnergyResult, totalGeneratedEnergy } = calculateGeneratedEnergyHandler(result);

            setGeneratedEnergy({ values: generatedEnergyResult.filter(x => x.generatedEnergy > 0), total: totalGeneratedEnergy });
            setSelectedOption('energy');
        } catch (error) {
            setError('Invalid data!');
        }
    }

    //* Event handlers
    const handleDateChange = (inputDate) => setDate(dayjs(inputDate));
    const handleSunTypeChange = (event) => setSunInfoType(event.target.value);
    const handlePanelTypeChange = (event) => setPanelInfoType(event.target.value);
    const handleOptionChange = (event) => setSelectedOption(event.target.value);
    const handleChangeTextArea = (event) => setText(event.target.value);

    const getTextHandler = () => {
        const data = JSON.parse(text);
        const records = data.records.map(record => ({
            time: record.datetime,
            generatedEnergy: Number(record.energy)
        }));
        records?.length > 0 ? setUserData(records) : setUserData([]);
    };

    //* Formik configuration
    let formik = useFormik({
        initialValues: { lat: 42.874221, lon: 25.318684, date: new Date(), panelAzimuth: 0, panelAltitude: 0, panelPower: 0 },
        validationSchema: eventInfoSchema,
        onSubmit: onSubmitHandler
    });

    return (
        <Grid container spacing={6} sx={{ padding: 3 }}>
            <Grid item xs={12} md={12} lg={12}>
                <Paper>
                    <Box sx={{ padding: 5 }}>
                        <Grid container spacing={2}>
                            {selectedOption === 'calculation' ? <>
                                <Grid item xs={12} sm={12} lg={12}>
                                    <Typography variant="h5" fontWeight="bold" sx={{ paddingTop: 2, textAlign: 'center', display: 'block' }} gutterBottom>Calculation generated panel energy</Typography>
                                </Grid>
                                <LocationForm formik={formik} />
                                <PanelForm formik={formik} />
                                <Grid item xs={12} sm={4} lg={6}>
                                    <CustomDatePicker onChange={handleDateChange} />
                                </Grid>

                                <Grid item xs={12} sm={12} lg={12}>
                                    <TextareaAutosize
                                        minRows={5}
                                        value={text}
                                        onChange={handleChangeTextArea}
                                        placeholder="Enter your data here"
                                        style={{ width: '100%', borderRadius: 5, resize: 'vertical' }} />
                                    <Button variant="contained" onClick={getTextHandler}>Upload user data</Button>
                                </Grid>

                                {/* Error Alert */}
                                {error ? <Grid style={{ display: 'flex', justifyContent: 'center' }} item xs={12} sm={12} lg={12}>
                                    <Alert sx={{ justifyContent: 'center' }} severity="error">{error}</Alert>
                                </Grid> : null}

                                {/* Submit Button */}
                                <Grid sx={{ display: 'flex', justifyContent: 'flex-end' }} item xs={12} sm={12} lg={12}>
                                    <Button variant="contained" onClick={formik.handleSubmit}>Show result</Button>
                                </Grid>
                            </> : null}

                            <Grid item xs={12} sm={12} md={12} lg={12}>
                                <Typography variant="h5" fontWeight="bold" sx={{ paddingTop: 2, textAlign: 'center' }} gutterBottom>Select an Option:</Typography>
                                <RadioGroup sx={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-around' }} value={selectedOption} onChange={handleOptionChange}>
                                    <FormControlLabel onChange={() => {
                                        setGeneratedEnergy({ total: 0, values: [] });
                                        setData([]);
                                    }} value='calculation' control={<Radio />} label='New Calculation' />
                                    <FormControlLabel disabled={generatedEnergy?.values?.length === 0} value='energy' control={<Radio />} label='Show Generated Energy' />
                                    <FormControlLabel disabled={data?.length === 0} value='power' control={<Radio />} label='Show Generated Power' />
                                </RadioGroup>
                            </Grid>

                            {/* Display generated energy for selected date */}
                            {generatedEnergy.total ? <Grid item xs={12} sm={12} lg={12}>
                                <Typography variant='h6' gutterBottom sx={{ textAlign: 'center', fontWeight: 'bold' }}>For {dayjs(date?.$d).format('DD MMMM YYYY')} you will generate {generatedEnergy?.total} W/h</Typography>
                            </Grid> : null}

                            {selectedOption === 'energy' ? <GeneratedEnergyPage generatedEnergy={generatedEnergy} date={date} panelInfoType={panelInfoType} handlePanelTypeChange={handlePanelTypeChange} /> : null}
                            {selectedOption === 'power' ? <GeneratedPowerPage data={data} sunInfoType={sunInfoType} userData={userData} handleSunTypeChange={handleSunTypeChange} /> : null}
                        </Grid>
                    </Box>
                </Paper>
            </Grid>
        </Grid >
    );
}

export default Dashboard;