//* Date imports
import moment from 'moment';

//* Material-UI and nivo imports
import { ResponsiveLine } from '@nivo/line';
import { Box, Typography } from "@mui/material";

const ChartComponent = ({ params }) => {
    //* Prepare data for the chart
    const chartData = [{
        id: 'Generated Energy',
        data: params.map((param) => {
            return { x: param.time.includes('-') ? moment(param.time.split(' - ')[0]).format('HH:mm') : param.time, y: param.generatedEnergy };
        })
    }];

    //* Define options for the chart
    const chartOptions = {
        margin: { top: 50, right: 50, bottom: 50, left: 50 },
        xScale: { type: 'point' },
        yScale: { type: 'linear', min: 0, max: 'auto' },
        axisBottom: { tickRotation: -85 },
        enableSlices: 'x',
        sliceTooltip: ({ slice }) => (
            <Box>
                {/* Render the tooltip for each data point in the slice */}
                {slice.points.map((point) => (
                    <Box key={point.id}>
                        <Typography sx={{ fontWeight: 'bold' }}>{point.data.xFormatted}</Typography>
                        <Box>Generated Energy: {point.data.yFormatted}W</Box>
                    </Box>
                ))}
            </Box>
        ),
    };

    return (
        <Box>
            <Box style={{ height: '400px' }}>
                {/* Render the responsive line chart */}
                <ResponsiveLine data={chartData} {...chartOptions} />
            </Box>
        </Box>
    );
};

export default ChartComponent;