import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs'
import { DateTimePicker, DatePicker, LocalizationProvider } from '@mui/x-date-pickers';

const CustomDatePicker = ({ onChange }) => {
  return (
    <LocalizationProvider dateAdapter={AdapterDayjs}>
      {/*
      <DateTimePicker
        label="Select date and time"
        sx={{ minWidth: '100%' }}
        ampm={false}
        showTodayButton
        showTabs
        ampmInClock={false}
        clearable
        format="DD/MM/YYYY HH:mm"
        onChange={(newValue) => onChange(newValue)}
        renderInput={(props) => <TextField {...props} />}
      />
       */}
      <DatePicker
        label="Select date"
        sx={{ minWidth: '100%' }}
        onChange={(newValue) => onChange(newValue)} />
    </LocalizationProvider>
  );
}

export default CustomDatePicker;