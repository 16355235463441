//* Date imports
import moment from 'moment';

//* Material-UI and nivo imports
import { Box } from "@mui/material";
import { ResponsiveLine } from "@nivo/line";

//* Utility function imports
import { performLinearInterpolation } from "../utils/sunCalcHandler";

const LineChart = ({ params, userData }) => {
    //* Convert params data to chartData1 array
    const chartData1 = params?.map((param) => {
        return { x: param.time, y: Number(param.generatedEnergy) };
    })

    //* Convert userData to chartData2 array
    const chartData2 = userData?.map((entry) => {
        return { x: entry.time, y: entry.generatedEnergy };
    });

    //* Custom tick format function
    const formatTick = (value) => value.replace(/'/g, '');

    //* Perform linear interpolation on chartData1 and chartData2
    const interpolatedData = performLinearInterpolation(chartData1, chartData2);

    //* Format x-values of chartData1 and interpolatedData
    const combinedData = [
        {
            id: 'Generated Energy', data: chartData1.map((param) => {
                return {
                    x: moment(param.x).format('HH:mm'),
                    y: Number(param.y),
                };
            })
        },
        {
            id: 'User Data', data: interpolatedData.map((param) => {
                return {
                    x: moment(param.x).format('HH:mm'),
                    y: Number(param.y),
                };
            })
        }
    ];

    return (
        <Box style={{ display: 'flex', height: '400px' }}>
            <Box style={{ flex: 1, marginRight: '20px' }}>
                <ResponsiveLine
                    data={combinedData}
                    colors={['red', 'blue']}
                    margin={{ top: 50, right: 110, bottom: 80, left: 60 }}
                    xScale={{ type: "point" }}
                    yScale={{
                        type: "linear",
                        min: 0,
                        max: "auto",
                        stacked: false,
                        reverse: false,
                    }}
                    curve="catmullRom"
                    axisBottom={{
                        orient: 'bottom',
                        tickRotation: -90,
                        tickPadding: 13,
                        tickFormat: formatTick, // Apply the custom tick format function
                    }}
                    pointSize={8}
                    pointBorderWidth={2}
                    pointLabelYOffset={-12}
                    useMesh={true}
                    axisTop={null}
                    axisRight={null}
                    enableGridX={false}
                    enableGridY={false}
                    legends={[
                        {
                            anchor: "bottom-right",
                            direction: "column",
                            justify: false,
                            translateX: 100,
                            translateY: 0,
                            itemsSpacing: 0,
                            itemDirection: "left-to-right",
                            itemWidth: 80,
                            itemHeight: 20,
                            itemOpacity: 0.75,
                            symbolSize: 12,
                            symbolShape: "circle",
                            symbolBorderColor: "rgba(0, 0, 0, .5)",
                            effects: [
                                {
                                    on: "hover",
                                    style: {
                                        itemBackground: "rgba(0, 0, 0, .03)",
                                        itemOpacity: 1,
                                    },
                                },
                            ],
                        },
                    ]}
                />
            </Box>
        </Box>
    );
};

export default LineChart;