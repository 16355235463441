import { Grid, TextField, } from '@mui/material';

const PanelForm = ({ formik }) => (
  <>
    <Grid item xs={12} sm={4} lg={6}>
      <TextField
        required
        fullWidth
        type='number'
        id="panelAzimuth"
        name="panelAzimuth"
        sx={{ fontSize: '14px' }}
        onBlur={formik.handleBlur}
        label='Panel azimuth'
        onChange={formik.handleChange}
        error={formik.touched.panelAzimuth && Boolean(formik.errors.panelAzimuth)}
        helperText={formik.touched.panelAzimuth && formik.errors.panelAzimuth}
        defaultValue={formik.values.panelAzimuth} />
    </Grid>

    <Grid item xs={12} sm={4} lg={6}>
      <TextField
        required
        fullWidth
        type='number'
        id="panelAltitude"
        name="panelAltitude"
        sx={{ fontSize: '14px' }}
        onBlur={formik.handleBlur}
        label='Panel altitude'
        onChange={formik.handleChange}
        error={formik.touched.panelAltitude && Boolean(formik.errors.panelAltitude)}
        helperText={formik.touched.panelAltitude && formik.errors.panelAltitude}
        defaultValue={formik.values.panelAltitude} />
    </Grid>

    <Grid item xs={12} sm={4} lg={6}>
      <TextField
        required
        fullWidth
        type='number'
        id="panelPower"
        name="panelPower"
        label='Panel power'
        sx={{ fontSize: '14px' }}
        onBlur={formik.handleBlur}
        onChange={formik.handleChange}
        error={formik.touched.panelPower && Boolean(formik.errors.panelPower)}
        helperText={formik.touched.panelPower && formik.errors.panelPower}
        defaultValue={formik.values.panelPower} />
    </Grid>
  </>
);

export default PanelForm;