import { Grid, TextField, } from '@mui/material';

const LocationForm = ({ formik }) => (
  <>
    <Grid item xs={12} sm={4} lg={6}>
      <TextField
        id="lat"
        required
        fullWidth
        name="lat"
        sx={{ fontSize: '14px' }}
        label='Latitude'
        onChange={formik.handleChange}
        onBlur={formik.handleBlur}
        error={formik.touched.lat && Boolean(formik.errors.lat)}
        helperText={formik.touched.lat && formik.errors.lat}
        defaultValue={formik.values.lat} />
    </Grid>

    <Grid item xs={12} sm={4} lg={6}>
      <TextField
        id="lon"
        required
        fullWidth
        name="lon"
        label='Longitude '
        sx={{ fontSize: '14px' }}
        onBlur={formik.handleBlur}
        onChange={formik.handleChange}
        error={formik.touched.lon && Boolean(formik.errors.lon)}
        helperText={formik.touched.lon && formik.errors.lon}
        defaultValue={formik.values.lon} />
    </Grid>
  </>
);

export default LocationForm;