//* Component imports
import LineChart from '../components/LineChart';
import TableResult from '../components/TableResult';

//* Material-UI imports
import { FormControl, Grid, InputLabel, MenuItem, Select, Paper } from '@mui/material';

const GeneratedPowerPage = ({ data, sunInfoType, userData, handleSunTypeChange }) => {
    return (
        <>
            {/* Solar and Sun Result Type Dropdown */}
            {data.length > 0 ? <Grid item xs={12} sm={12} md={12} lg={12}>
                <FormControl fullWidth>
                    <InputLabel id="result-type">Solar and Sun result</InputLabel>
                    <Select
                        label='Solar and sun result'
                        id="result-type"
                        value={sunInfoType}
                        labelId="result-type-label"
                        onChange={handleSunTypeChange}>
                        <MenuItem value="table">Table</MenuItem>
                        <MenuItem value="chart">Chart</MenuItem>
                    </Select>
                </FormControl>
            </Grid> : null}

            {(data.length > 0 && sunInfoType) ? <Grid item xs={12} sm={12} md={12} lg={12}>
                {/* Solar and Sun Table */}
                {sunInfoType === 'table' ? <TableResult component={Paper} rows={data} /> : null}

                {/* Solar and Sun Chart */}
                {sunInfoType === 'chart' ? <LineChart params={data} userData={userData} unit={'W'} /> : null}
            </Grid> : null}
        </>
    )
}

export default GeneratedPowerPage;