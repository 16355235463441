import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from '@mui/material';

const TableResult = ({ component, rows }) => {
    return (
        <TableContainer component={component}>
            <Table sx={{ minWidth: 650 }} aria-label="simple table">
                <TableHead>
                    <TableRow>
                        <TableCell style={{ textAlign: 'center' }}>Time</TableCell>
                        <TableCell style={{ textAlign: 'center' }}>Vertical angle between the sun and the panel</TableCell>
                        <TableCell style={{ textAlign: 'center' }}>Horizontal angle between the sun and the panel</TableCell>
                        <TableCell style={{ textAlign: 'center' }} align="right">Coefficient</TableCell>
                        <TableCell style={{ textAlign: 'center' }} align="right">Generated Power</TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {/* Map through the rows and create a table row for each */}
                    {rows.map((row, index) => (
                        <TableRow key={index}>
                            <TableCell style={{ textAlign: 'center' }} component="th" scope="row">{row.time}</TableCell>
                            <TableCell style={{ textAlign: 'center' }} component="th" scope="row">{row.verticalSunPanelAngle}</TableCell>
                            <TableCell style={{ textAlign: 'center' }} component="th" scope="row">{row.horizontalSunPanelAngle}</TableCell>
                            <TableCell style={{ textAlign: 'center' }} align="right">{row.coefficient}</TableCell>
                            <TableCell style={{ textAlign: 'center' }} align="right">{row.generatedEnergy} W</TableCell>
                        </TableRow>
                    ))}
                </TableBody>
            </Table>
        </TableContainer>
    );
}

export default TableResult;